@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

button {
    font-family: inherit;
}

table {
    width: fit-content;
    border-spacing: 0px;
    border-collapse: separate;
}

table.fit-content {
    width: fit-content;
}

table thead {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

table .index {
    /* darker background color */
    background: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
}

table .header-cell {
    padding: 5px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
